import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Box,
    Card,
    CardContent,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Chip
} from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { LegoPart, LegoColor, PriceGuideData, PriceDetail, PriceGuide } from '../../types/lego.types';
import { apiService } from '../../services/api.service';

interface PartDetailsProps {
    open: boolean;
    onClose: () => void;
    part: LegoPart | null;
}

interface PriceDetailTableProps {
    details: PriceDetail[];
    isSold: boolean;
}

const ColorBox: React.FC<{ colorCode: string }> = ({ colorCode }) => (
    <Box
        sx={{
            width: 20,
            height: 20,
            bgcolor: `#${colorCode}`,
            border: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'inline-block',
            verticalAlign: 'middle',
            mr: 1
        }}
    />
);

const typeColors: { [key: string]: string } = {
    MINIFIG: '#FFA726', // Orange
    PART: '#66BB6A', // Green
    SET: '#42A5F5', // Blue
    BOOK: '#AB47BC', // Purple
    GEAR: '#EF5350', // Red
    CATALOG: '#EC407A', // Pink
    INSTRUCTION: '#7E57C2', // Deep Purple
    UNSORTED_LOT: '#78909C', // Blue Grey
    ORIGINAL_BOX: '#FFB74D', // Orange Light
};

type SortField = 'quantity' | 'unit_price' | 'date_ordered';
type SortOrder = 'asc' | 'desc';

const PriceDetailTable: React.FC<PriceDetailTableProps & { 
    onLoadMore?: () => void; 
    hasMore?: boolean;
    loadingMore?: boolean;
}> = ({ 
    details, 
    isSold,
    onLoadMore,
    hasMore,
    loadingMore 
}) => {
    const [sortField, setSortField] = useState<SortField>(isSold ? 'date_ordered' : 'unit_price');
    const [sortOrder, setSortOrder] = useState<SortOrder>(isSold ? 'desc' : 'asc');

    const handleSort = (field: SortField) => {
        if (sortField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder(field === 'date_ordered' ? 'desc' : 'asc');
        }
    };

    const sortedDetails = [...details].sort((a, b) => {
        const multiplier = sortOrder === 'asc' ? 1 : -1;
        
        switch (sortField) {
            case 'quantity':
                return (a.quantity - b.quantity) * multiplier;
            case 'unit_price':
                return (parseFloat(a.unit_price) - parseFloat(b.unit_price)) * multiplier;
            case 'date_ordered':
                if (!a.date_ordered || !b.date_ordered) return 0;
                return (new Date(a.date_ordered).getTime() - new Date(b.date_ordered).getTime()) * multiplier;
            default:
                return 0;
        }
    });

    return (
        <>
            <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortField === 'quantity'}
                                    direction={sortField === 'quantity' ? sortOrder : 'asc'}
                                    onClick={() => handleSort('quantity')}
                                >
                                    Quantity
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortField === 'unit_price'}
                                    direction={sortField === 'unit_price' ? sortOrder : 'asc'}
                                    onClick={() => handleSort('unit_price')}
                                >
                                    Unit Price
                                </TableSortLabel>
                            </TableCell>
                            {isSold ? (
                                <>
                                    <TableCell>Seller</TableCell>
                                    <TableCell>Buyer</TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortField === 'date_ordered'}
                                            direction={sortField === 'date_ordered' ? sortOrder : 'desc'}
                                            onClick={() => handleSort('date_ordered')}
                                        >
                                            Date
                                        </TableSortLabel>
                                    </TableCell>
                                </>
                            ) : (
                                <TableCell>Shipping</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedDetails.map((detail, index) => (
                            <TableRow key={index}>
                                <TableCell>{detail.quantity}</TableCell>
                                <TableCell>${parseFloat(detail.unit_price).toFixed(3)}</TableCell>
                                {isSold ? (
                                    <>
                                        <TableCell>{detail.seller_country_code}</TableCell>
                                        <TableCell>{detail.buyer_country_code}</TableCell>
                                        <TableCell>
                                            {detail.date_ordered ? 
                                                new Date(detail.date_ordered).toLocaleDateString() : 
                                                '-'
                                            }
                                        </TableCell>
                                    </>
                                ) : (
                                    <TableCell>
                                        {detail.shipping_available ? 'Yes' : 'No'}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {hasMore && (
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                        variant="outlined"
                        onClick={onLoadMore}
                        startIcon={loadingMore ? <CircularProgress size={20} /> : <MoreHoriz />}
                        disabled={loadingMore}
                    >
                        {loadingMore ? 'Loading...' : 'Load More'}
                    </Button>
                </Box>
            )}
        </>
    );
};

const PriceGuideSection: React.FC<{ 
    stockGuide?: PriceGuide;
    soldGuide?: PriceGuide;
    onLoadMore?: () => void;
    hasMore?: boolean;
    loadingMore?: boolean;
}> = ({ stockGuide, soldGuide, onLoadMore, hasMore, loadingMore }) => (
    <Grid container spacing={3}>
        {/* Price Summary */}
        <Grid item xs={12}>
            <Grid container spacing={3}>
                {stockGuide && (
                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Current Stock</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Min Price: ${parseFloat(stockGuide.price_data.min_price).toFixed(3)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Max Price: ${parseFloat(stockGuide.price_data.max_price).toFixed(3)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Qty Avg Price: ${parseFloat(stockGuide.price_data.qty_avg_price).toFixed(3)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Total Quantity: {stockGuide.price_data.total_quantity}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {soldGuide && (
                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Recently Sold</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Min Price: ${parseFloat(soldGuide.price_data.min_price).toFixed(3)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Max Price: ${parseFloat(soldGuide.price_data.max_price).toFixed(3)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Qty Avg Price: ${parseFloat(soldGuide.price_data.qty_avg_price).toFixed(3)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Total Quantity: {soldGuide.price_data.total_quantity}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Grid>

        {/* Price Details Tables */}
        <Grid item xs={12}>
            <Grid container spacing={3}>
                {stockGuide && (
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" gutterBottom>Stock Details</Typography>
                        <PriceDetailTable 
                            details={stockGuide.price_data.price_detail} 
                            isSold={false}
                            onLoadMore={onLoadMore}
                            hasMore={hasMore}
                            loadingMore={loadingMore}
                        />
                    </Grid>
                )}
                {soldGuide && (
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" gutterBottom>Sold Details</Typography>
                        <PriceDetailTable 
                            details={soldGuide.price_data.price_detail} 
                            isSold={true}
                            onLoadMore={onLoadMore}
                            hasMore={hasMore}
                            loadingMore={loadingMore}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    </Grid>
);

const PartDetails: React.FC<PartDetailsProps> = ({ open, onClose, part }) => {
    const [selectedColorId, setSelectedColorId] = useState<number | null>(null);
    const [colors, setColors] = useState<Map<number, LegoColor>>(new Map());
    const [loading, setLoading] = useState(false);
    const [loadingPriceGuide, setLoadingPriceGuide] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [stockGuide, setStockGuide] = useState<PriceGuide | null>(null);
    const [soldGuide, setSoldGuide] = useState<PriceGuide | null>(null);

    // Reset state when part changes
    useEffect(() => {
        if (part?.knownColors?.length) {
            // Set first color as default
            const firstColor = part.knownColors[0];
            setSelectedColorId(firstColor.color_id);
        } else {
            setSelectedColorId(null);
        }
    }, [part]);

    // Fetch colors
    useEffect(() => {
        const fetchColors = async () => {
            if (!part?.knownColors?.length) return;

            setLoading(true);
            try {
                const colorIds = part.knownColors.map(kc => kc.color_id);
                const colorsResponse = await apiService.getColorsByIds(colorIds);

                // Create map of colors
                const colorMap = new Map<number, LegoColor>(
                    colorsResponse.data.data.map((color: LegoColor) => [color.color_id, color])
                );
                setColors(colorMap);
            } catch (error) {
                console.error('Error fetching colors:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchColors();
    }, [part]);

    // Fetch price guide when color changes
    useEffect(() => {
        const fetchPriceGuide = async () => {
            if (!part || !open) return;

            setLoadingPriceGuide(true);
            try {
                const response = part.type === 'PART' && selectedColorId
                    ? await apiService.getPartPriceGuide(part.no, selectedColorId)
                    : await apiService.getPriceGuide(part.type, part.no);

                const { stock, sold } = response.data.data;
                setStockGuide(stock || null);
                setSoldGuide(sold || null);
                setHasMore(response.data.hasMore || false);
            } catch (error) {
                console.error('Error fetching price guide:', error);
            } finally {
                setLoadingPriceGuide(false);
            }
        };

        fetchPriceGuide();
    }, [part, selectedColorId, open]);

    // Handle loading more price details
    const handleLoadMore = async () => {
        if (!part || loadingMore) return;

        setLoadingMore(true);
        try {
            const response = part.type === 'PART' && selectedColorId
                ? await apiService.getPartPriceGuide(part.no, selectedColorId, true)
                : await apiService.getPriceGuide(part.type, part.no);

            const { stock, sold } = response.data.data;

            // Merge new price details with existing ones
            if (stock && stockGuide) {
                setStockGuide({
                    ...stock,
                    price_data: {
                        ...stock.price_data,
                        price_detail: [
                            ...stockGuide.price_data.price_detail,
                            ...stock.price_data.price_detail.slice(10)
                        ]
                    }
                });
            }

            if (sold && soldGuide) {
                setSoldGuide({
                    ...sold,
                    price_data: {
                        ...sold.price_data,
                        price_detail: [
                            ...soldGuide.price_data.price_detail,
                            ...sold.price_data.price_detail.slice(10)
                        ]
                    }
                });
            }

            setHasMore(false); // All data loaded
        } catch (error) {
            console.error('Error loading more price details:', error);
        } finally {
            setLoadingMore(false);
        }
    };

    if (!part) return null;

    const getImageUrl = (part: LegoPart, colorId?: number) => {
        if (part.type === 'MINIFIG') {
            return `https://img.bricklink.com/ItemImage/MN/0/${part.no}.png`;
        }
        return `https://img.bricklink.com/ItemImage/PN/${colorId || 0}/${part.no}.png`;
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            scroll="paper"
        >
            <DialogTitle>
                <Typography variant="h5" component="div">
                    {part.name} ({part.no})
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    {/* Part Image and Basic Details */}
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Box
                                    component="img"
                                    src={getImageUrl(part, selectedColorId || undefined)}
                                    alt={part.name}
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'contain',
                                        mb: 2
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>Details</Typography>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Part Number:</strong> {part.no}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Name:</strong> {part.name}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Type:</strong>{' '}
                                        <Chip
                                            label={part.type}
                                            size="small"
                                            sx={{
                                                bgcolor: typeColors[part.type] || 'grey.500',
                                                color: 'white',
                                                fontWeight: 'bold'
                                            }}
                                        />
                                    </Typography>
                                    {loading ? (
                                        <Box display="flex" justifyContent="center" p={3}>
                                            <CircularProgress />
                                        </Box>
                                    ) : part.knownColors && part.knownColors.length > 0 && (
                                        <FormControl fullWidth sx={{ mt: 2 }}>
                                            <InputLabel>Color</InputLabel>
                                            <Select
                                                value={selectedColorId || ''}
                                                label="Color"
                                                onChange={(e: SelectChangeEvent<number>) => 
                                                    setSelectedColorId(e.target.value as number)
                                                }
                                            >
                                                {part.knownColors.map((knownColor) => {
                                                    const color = colors.get(knownColor.color_id);
                                                    return (
                                                        <MenuItem key={knownColor.color_id} value={knownColor.color_id}>
                                                            {color && (
                                                                <Box display="flex" alignItems="center">
                                                                    <ColorBox colorCode={color.color_code} />
                                                                    <span>{color.color_name}</span>
                                                                </Box>
                                                            )}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Price Guide Section */}
                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" gutterBottom>Price Guide</Typography>
                        {loadingPriceGuide ? (
                            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" p={3}>
                                <CircularProgress sx={{ mb: 2 }} />
                                <Typography color="text.secondary">Loading price guide data...</Typography>
                            </Box>
                        ) : stockGuide || soldGuide ? (
                            <PriceGuideSection 
                                stockGuide={stockGuide || undefined}
                                soldGuide={soldGuide || undefined}
                                hasMore={hasMore}
                                loadingMore={loadingMore}
                                onLoadMore={handleLoadMore}
                            />
                        ) : (
                            <Typography color="text.secondary">No price guide data available</Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PartDetails;
