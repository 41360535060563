import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    TextField,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
    CircularProgress,
    Pagination,
    SelectChangeEvent,
    CardActionArea
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { apiService } from '../../services/api.service';
import { LegoSet } from '../../types/lego.types';
import { LegoSetDetails } from './LegoSetDetails';

const LegoSets: React.FC = () => {
    const [sets, setSets] = useState<LegoSet[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchInput, setSearchInput] = useState('');
    const [selectedYear, setSelectedYear] = useState<string>('');
    const [years, setYears] = useState<number[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedSet, setSelectedSet] = useState<LegoSet | null>(null);
    const [detailsOpen, setDetailsOpen] = useState(false);

    // Search timer ref
    const searchTimerRef = React.useRef<NodeJS.Timeout>();

    const fetchSets = useCallback(async (searchTerm: string) => {
        try {
            setLoading(true);
            setError(null);

            const params: any = {
                page,
                limit: 12
            };

            if (searchTerm.trim()) params.search = searchTerm;
            if (selectedYear) params.year = selectedYear;

            const response = await apiService.getLegoSets(params);
            setSets(response.data.data.sets);
            setTotalPages(response.data.data.pagination.pages);
            
            // Set years on first load
            if (!years.length && response.data.data.filters.years) {
                setYears(response.data.data.filters.years);
            }
        } catch (err: any) {
            setError(err.response?.data?.message || 'Failed to fetch LEGO sets');
            setSets([]); // Clear sets on error
        } finally {
            setLoading(false);
        }
    }, [page, selectedYear, years.length]);

    // Initial load
    useEffect(() => {
        fetchSets('');
    }, [fetchSets]);

    // Handle search input changes with debouncing
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setSearchInput(newValue);
        
        // Clear existing timer
        if (searchTimerRef.current) {
            clearTimeout(searchTimerRef.current);
        }

        // Set new timer
        searchTimerRef.current = setTimeout(() => {
            setPage(1);
            fetchSets(newValue);
        }, 300);
    };

    const handleYearChange = (event: SelectChangeEvent) => {
        setSelectedYear(event.target.value);
        setPage(1);
        fetchSets(searchInput);
    };

    const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        fetchSets(searchInput);
    };

    const getImageUrl = (url: string | null | undefined) => {
        if (!url) return 'placeholder-image-url.jpg'; // You might want to add a placeholder image
        return url.startsWith('//') ? `https:${url}` : url;
    };

    const handleSetClick = (set: LegoSet) => {
        setSelectedSet(set);
        setDetailsOpen(true);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                LEGO Sets
            </Typography>

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}

            <Box sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Search LEGO Sets"
                            value={searchInput}
                            onChange={handleSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Year</InputLabel>
                            <Select
                                value={selectedYear}
                                label="Year"
                                onChange={handleYearChange}
                            >
                                <MenuItem value="">All Years</MenuItem>
                                {years.map((year) => (
                                    <MenuItem key={year} value={year.toString()}>
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            {loading ? (
                <Box display="flex" justifyContent="center" p={3}>
                    <CircularProgress />
                </Box>
            ) : sets.length === 0 ? (
                <Alert severity="info">No LEGO sets found</Alert>
            ) : (
                <>
                    <Grid container spacing={3}>
                        {sets.map((set) => (
                            <Grid item key={set._id} xs={12} sm={6} md={4}>
                                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <CardActionArea onClick={() => handleSetClick(set)}>
                                        <CardMedia
                                            component="img"
                                            height="200"
                                            image={getImageUrl(set.imageUrl)}
                                            alt={set.name || 'LEGO Set'}
                                            sx={{ objectFit: 'contain', p: 2 }}
                                        />
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography gutterBottom variant="h6" component="h2">
                                                {set.name || 'Unnamed Set'}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Set Number: {set.no || 'Unknown'}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Year: {set.yearReleased || 'Unknown'}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </>
            )}

            <LegoSetDetails
                open={detailsOpen}
                onClose={() => {
                    setDetailsOpen(false);
                    setSelectedSet(null);
                }}
                set={selectedSet}
            />
        </Container>
    );
};

export default LegoSets;
