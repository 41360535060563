import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiService } from '../services/api.service';
import { Box, CircularProgress } from '@mui/material';

interface User {
    id: string;
    username: string;
    email: string;
    role: string;
}

interface AuthContextType {
    user: User | null;
    isAuthenticated: boolean;
    isAdmin: boolean;
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const validateAuth = useCallback(async () => {
        try {
            const storedUser = apiService.getCurrentUser();
            const token = apiService.getAuthToken();
            
            if (storedUser && token) {
                const isValid = await apiService.validateToken();
                
                if (isValid) {
                    setUser(storedUser);
                    setIsAuthenticated(true);
                    return true;
                } else {
                    apiService.logout();
                    setUser(null);
                    setIsAuthenticated(false);
                    return false;
                }
            }
            return false;
        } catch (error) {
            console.error('Auth validation error:', error);
            apiService.logout();
            setUser(null);
            setIsAuthenticated(false);
            return false;
        }
    }, []);

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                setIsLoading(true);
                const isValid = await validateAuth();
                
                if (!isValid) {
                    const isPublicRoute = ['/login', '/register'].includes(location.pathname);
                    if (!isPublicRoute) {
                        navigate('/login', { 
                            replace: true,
                            state: { from: location }
                        });
                    }
                }
            } finally {
                setIsLoading(false);
            }
        };

        initializeAuth();
    }, [validateAuth, navigate, location.pathname]);

    const login = async (email: string, password: string) => {
        try {
            const response = await apiService.login({ email, password });
            setUser(response.user);
            setIsAuthenticated(true);
            
            // Navigate to the intended URL or sets page
            const intendedPath = location.state?.from?.pathname || '/sets';
            navigate(intendedPath, { replace: true });
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = () => {
        apiService.logout();
        setUser(null);
        setIsAuthenticated(false);
        navigate('/login', { replace: true });
    };

    const isAdmin = user?.role === 'admin';

    if (isLoading) {
        return (
            <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                minHeight="100vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <AuthContext.Provider value={{ user, isAuthenticated, isAdmin, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
