import React from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Box
} from '@mui/material';
import { PriceGuideData } from '../../types/lego.types';

interface PriceGuideProps {
    priceGuide: PriceGuideData;
}

const PriceGuideSection: React.FC<{ 
    title: string;
    data: {
        min_price: string;
        max_price: string;
        avg_price: string;
        qty_avg_price: string;
        unit_quantity: number;
        total_quantity: number;
    };
}> = ({ title, data }) => (
    <Card variant="outlined">
        <CardContent>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                        Min Price: ${parseFloat(data.min_price).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                        Max Price: ${parseFloat(data.max_price).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                        Average Price: ${parseFloat(data.avg_price).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                        Qty Avg Price: ${parseFloat(data.qty_avg_price).toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                        Unit Quantity: {data.unit_quantity}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="text.secondary">
                        Total Quantity: {data.total_quantity}
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export const PriceGuide: React.FC<PriceGuideProps> = ({ priceGuide }) => {
    return (
        <Grid container spacing={3}>
            {priceGuide.stock && (
                <Grid item xs={12} md={6}>
                    <PriceGuideSection 
                        title="Current Stock" 
                        data={priceGuide.stock.price_data} 
                    />
                </Grid>
            )}
            {priceGuide.sold && (
                <Grid item xs={12} md={6}>
                    <PriceGuideSection 
                        title="Recently Sold" 
                        data={priceGuide.sold.price_data} 
                    />
                </Grid>
            )}
        </Grid>
    );
};
