import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

interface ProtectedRouteProps {
    children?: React.ReactNode;
    requireAdmin?: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requireAdmin = false }) => {
    const { isAuthenticated, isAdmin } = useAuth();
    const location = useLocation();

    // If we're on the login page, render normally
    if (location.pathname === '/login') {
        return <>{children || <Outlet />}</>;
    }

    // If not authenticated, redirect to login
    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // If admin is required but user is not admin
    if (requireAdmin && !isAdmin) {
        return <Navigate to="/sets" replace />;
    }

    // If children are provided, render them, otherwise render the Outlet
    return <>{children || <Outlet />}</>;
};
