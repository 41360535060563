import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import { LegoPartsMap, Subset } from '../../types/lego.types';

interface PartOutSummaryProps {
    parts: LegoPartsMap;
    subsets: Subset[];
}

interface RatioData {
    stockTotal: number;
    soldTotal: number;
    partCount: number;
}

export const PartOutSummary: React.FC<PartOutSummaryProps> = ({ parts, subsets }) => {
    const calculateTotalValue = () => {
        let stockTotal = 0;
        let soldTotal = 0;
        let stockCount = 0;
        let soldCount = 0;

        subsets.forEach(subset => {
            const part = parts[subset.no];
            if (!part) return;

            // Find matching color guide for parts
            const stockGuide = part.type === 'PART'
                ? part.stockPriceGuides?.find(g => g.color_id === subset.color_id)
                : part.stockPriceGuides?.[0];

            const soldGuide = part.type === 'PART'
                ? part.soldPriceGuides?.find(g => g.color_id === subset.color_id)
                : part.soldPriceGuides?.[0];

            if (stockGuide?.price_data) {
                stockTotal += parseFloat(stockGuide.price_data.min_price) * subset.quantity;
                stockCount++;
            }

            if (soldGuide?.price_data) {
                soldTotal += parseFloat(soldGuide.price_data.qty_avg_price) * subset.quantity;
                soldCount++;
            }
        });

        return {
            stockTotal,
            soldTotal,
            stockCount,
            soldCount,
            totalParts: subsets.length
        };
    };

    const calculateRatioData = (maxRatio: number): RatioData => {
        const data: RatioData = {
            stockTotal: 0,
            soldTotal: 0,
            partCount: 0
        };

        subsets.forEach(subset => {
            const part = parts[subset.no];
            if (!part) return;

            // Find matching color guides for parts
            const stockGuide = part.type === 'PART'
                ? part.stockPriceGuides?.find(g => g.color_id === subset.color_id)
                : part.stockPriceGuides?.[0];
            
            const soldGuide = part.type === 'PART'
                ? part.soldPriceGuides?.find(g => g.color_id === subset.color_id)
                : part.soldPriceGuides?.[0];

            if (!stockGuide?.price_data || !soldGuide?.price_data) return;

            const stockQuantity = stockGuide.price_data.total_quantity;
            const soldQuantity = soldGuide.price_data.total_quantity;

            if (soldQuantity === 0) return;

            const ratio = stockQuantity / soldQuantity;
            
            // Only include parts with ratio less than maxRatio
            if (maxRatio === Infinity || ratio <= maxRatio) {
                data.stockTotal += parseFloat(stockGuide.price_data.min_price) * subset.quantity;
                data.soldTotal += parseFloat(soldGuide.price_data.qty_avg_price) * subset.quantity;
                data.partCount++;
            }
        });

        return data;
    };

    const totals = calculateTotalValue();
    const ratioThresholds = [
        { label: '1:1', value: 1 },
        { label: '3:1', value: 3 },
        { label: '5:1', value: 5 },
        { label: '10:1', value: 10 },
        { label: 'All', value: Infinity }
    ];

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Current Stock Value</Typography>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Total Value:</strong> ${totals.stockTotal.toFixed(2)}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Parts with Prices:</strong> {totals.stockCount} / {totals.totalParts}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Average Part Value:</strong> ${(totals.stockTotal / totals.stockCount).toFixed(2)}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Recently Sold Value</Typography>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Total Value:</strong> ${totals.soldTotal.toFixed(2)}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Parts with Prices:</strong> {totals.soldCount} / {totals.totalParts}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Average Part Value:</strong> ${(totals.soldTotal / totals.soldCount).toFixed(2)}
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Stock/Sold Ratio Breakdown</Typography>
                        <TableContainer component={Paper} variant="outlined">
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ratio</TableCell>
                                        <TableCell align="right">Current Stock Min</TableCell>
                                        <TableCell align="right">Recently Sold Avg</TableCell>
                                        <TableCell align="right">Part Count</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ratioThresholds.map(threshold => {
                                        const data = calculateRatioData(threshold.value);
                                        return (
                                            <TableRow key={threshold.label}>
                                                <TableCell>{threshold.label}</TableCell>
                                                <TableCell align="right">
                                                    ${data.stockTotal.toFixed(2)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    ${data.soldTotal.toFixed(2)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {data.partCount}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
