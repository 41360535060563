import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    Box,
    Divider,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip
} from '@mui/material';
import { LegoSet, LegoColor, LegoPartsMap, LegoPart, Subset, PriceGuideData, PriceGuide } from '../../types/lego.types';
import { apiService } from '../../services/api.service';
import PartDetails from './PartDetails';
import { PriceGuide as PriceGuideComponent } from './PriceGuide';
import { PartOutSummary } from './PartOutSummary';

interface LegoSetDetailsProps {
    open: boolean;
    onClose: () => void;
    set: LegoSet | null;
}

const ColorBox: React.FC<{ colorCode: string }> = ({ colorCode }) => (
    <Box
        sx={{
            width: 20,
            height: 20,
            bgcolor: `#${colorCode}`,
            border: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'inline-block',
            verticalAlign: 'middle',
            mr: 1
        }}
    />
);

const typeColors: { [key: string]: string } = {
    MINIFIG: '#FFA726', // Orange
    PART: '#66BB6A', // Green
    SET: '#42A5F5', // Blue
    BOOK: '#AB47BC', // Purple
    GEAR: '#EF5350', // Red
    CATALOG: '#EC407A', // Pink
    INSTRUCTION: '#7E57C2', // Deep Purple
    UNSORTED_LOT: '#78909C', // Blue Grey
    ORIGINAL_BOX: '#FFB74D', // Orange Light
};

export const LegoSetDetails: React.FC<LegoSetDetailsProps> = ({ open, onClose, set }) => {
    const [colors, setColors] = useState<Map<number, LegoColor>>(new Map());
    const [parts, setParts] = useState<LegoPartsMap>({});
    const [loading, setLoading] = useState(false);
    const [selectedPart, setSelectedPart] = useState<LegoPart | null>(null);
    const [partDetailsOpen, setPartDetailsOpen] = useState(false);
    const [priceGuide, setPriceGuide] = useState<PriceGuideData | null>(null);
    const [loadingPriceGuide, setLoadingPriceGuide] = useState(false);

    // Fetch colors, parts, and price guide when set changes
    useEffect(() => {
        const fetchData = async () => {
            if (!set) return;

            setLoading(true);
            setLoadingPriceGuide(true);
            try {
                // Get unique color IDs and part numbers
                const colorIds = Array.from(new Set(set.subsets.map(subset => subset.color_id)));
                const partNos = Array.from(new Set(set.subsets.map(subset => subset.no)));

                // Get part numbers and color IDs in the same order
                const partNumbers: string[] = [];
                const partColorIds: number[] = [];
                set.subsets.forEach(subset => {
                    partNumbers.push(subset.no);
                    partColorIds.push(subset.color_id);
                });

                // Make all API calls in parallel
                const [colorsResponse, partsResponse, priceGuideResponse] = await Promise.all([
                    apiService.getColorsByIds(colorIds),
                    apiService.getPartsByNumbers(partNumbers, partColorIds),
                    apiService.getPriceGuide('SET', set.no)
                ]);

                // Process responses
                const colorMap = new Map<number, LegoColor>(
                    colorsResponse.data.data.map((color: LegoColor) => [color.color_id, color])
                );
                setColors(colorMap);
                setParts(partsResponse.data.data);
                setPriceGuide(priceGuideResponse.data.data);
            } catch (error) {
                console.error('Error fetching set details:', error);
            } finally {
                setLoading(false);
                setLoadingPriceGuide(false);
            }
        };

        fetchData();
    }, [set]);

    if (!set) return null;

    const getPartImageUrl = (subset: Subset, part?: LegoPart) => {
        if (part?.type === 'MINIFIG') {
            return `https://img.bricklink.com/ItemImage/MN/0/${subset.no}.png`;
        }
        return `https://img.bricklink.com/ItemImage/PN/${subset.color_id}/${subset.no}.png`;
    };

    const handlePartClick = (subset: Subset) => {
        const part = parts[subset.no];
        if (part) {
            // Find the color in the part's known colors
            const knownColor = part.knownColors?.find(kc => kc.color_id === subset.color_id);
            if (knownColor) {
                // Create a new part object with only this color
                const colorSpecificPart: LegoPart = {
                    ...part,
                    knownColors: [knownColor]
                };
                setSelectedPart(colorSpecificPart);
                setPartDetailsOpen(true);
            }
        }
    };

    const getStockPrice = (subset: Subset) => {
        const part = parts[subset.no];
        if (!part?.stockPriceGuides?.length) return null;
        
        // Find matching color guide for parts
        const guide = part.type === 'PART'
            ? part.stockPriceGuides.find(g => g.color_id === subset.color_id)
            : part.stockPriceGuides[0];
        
        if (!guide?.price_data) return null;
        return parseFloat(guide.price_data.min_price);
    };

    const getSoldPrice = (subset: Subset) => {
        const part = parts[subset.no];
        if (!part?.soldPriceGuides?.length) return null;
        
        // Find matching color guide for parts
        const guide = part.type === 'PART'
            ? part.soldPriceGuides.find(g => g.color_id === subset.color_id)
            : part.soldPriceGuides[0];
        
        if (!guide?.price_data) return null;
        return parseFloat(guide.price_data.qty_avg_price);
    };

    const getStockSoldRatio = (subset: Subset) => {
        const stockPrice = getStockPrice(subset);
        const soldPrice = getSoldPrice(subset);
        if (!stockPrice || !soldPrice) return null;
        
        const part = parts[subset.no];
        if (!part?.stockPriceGuides?.length || !part?.soldPriceGuides?.length) return null;

        // Find matching color guides for parts
        const stockGuide = part.type === 'PART'
            ? part.stockPriceGuides.find(g => g.color_id === subset.color_id)
            : part.stockPriceGuides[0];
        
        const soldGuide = part.type === 'PART'
            ? part.soldPriceGuides.find(g => g.color_id === subset.color_id)
            : part.soldPriceGuides[0];
        
        if (!stockGuide?.price_data || !soldGuide?.price_data) return null;
        
        const stockQuantity = stockGuide.price_data.total_quantity;
        const soldQuantity = soldGuide.price_data.total_quantity;
        
        if (soldQuantity === 0) return null;
        return stockQuantity / soldQuantity;
    };

    const getRatioColor = (ratio: number | null) => {
        if (ratio === null) return 'inherit';
        if (ratio < 0.5) return 'error.main';
        if (ratio < 1) return 'warning.main';
        return 'success.main';
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            scroll="paper"
        >
            <DialogTitle>
                <Typography variant="h5" component="div">
                    {set.name} ({set.no})
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    {/* Set Image and Basic Details */}
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Box
                                    component="img"
                                    src={set.imageUrl}
                                    alt={set.name}
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'contain',
                                        mb: 2
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>Details</Typography>
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Set Number:</strong> {set.no}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Name:</strong> {set.name}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Year Released:</strong> {set.yearReleased}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Weight:</strong> {set.weight}g
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Dimensions:</strong> {set.dimX}x{set.dimY}x{set.dimZ} cm
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Price Guide Section */}
                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" gutterBottom>Price Guide</Typography>
                        {loadingPriceGuide ? (
                            <Box display="flex" justifyContent="center" p={3}>
                                <CircularProgress />
                            </Box>
                        ) : priceGuide ? (
                            <PriceGuideComponent priceGuide={priceGuide} />
                        ) : (
                            <Typography color="text.secondary">No price guide data available</Typography>
                        )}
                    </Grid>

                    {/* Part Out Summary */}
                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" gutterBottom>Part Out Summary</Typography>
                        {loading ? (
                            <Box display="flex" justifyContent="center" p={3}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <PartOutSummary parts={parts} subsets={set.subsets} />
                        )}
                    </Grid>

                    {/* Subset List */}
                    <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" gutterBottom>Subset List</Typography>
                        {loading ? (
                            <Box display="flex" justifyContent="center" p={3}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <TableContainer component={Paper} variant="outlined">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Image</TableCell>
                                            <TableCell>Part</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Color</TableCell>
                                            <TableCell align="right">Quantity</TableCell>
                                            <TableCell align="right">Extra</TableCell>
                                            <TableCell align="right">Stock Price</TableCell>
                                            <TableCell align="right">Sold Price</TableCell>
                                            <TableCell align="right">Stock/Sold</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {set.subsets.map((subset) => {
                                            const color = colors.get(subset.color_id);
                                            const part = parts[subset.no];
                                            const stockPrice = getStockPrice(subset);
                                            const soldPrice = getSoldPrice(subset);
                                            const ratio = getStockSoldRatio(subset);
                                            return (
                                                <TableRow
                                                    key={`${subset.no}-${subset.color_id}`}
                                                    hover
                                                    onClick={() => handlePartClick(subset)}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell>
                                                        <Box
                                                            component="img"
                                                            src={getPartImageUrl(subset, part)}
                                                            alt={subset.name}
                                                            sx={{
                                                                width: 60,
                                                                height: 60,
                                                                objectFit: 'contain'
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {subset.name}
                                                        </Typography>
                                                        <Typography variant="caption" color="text.secondary">
                                                            {subset.no}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {part?.type ? (
                                                            <Chip
                                                                label={part.type}
                                                                size="small"
                                                                sx={{
                                                                    bgcolor: typeColors[part.type] || 'grey.500',
                                                                    color: 'white',
                                                                    fontWeight: 'bold'
                                                                }}
                                                            />
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {color && (
                                                            <Box display="flex" alignItems="center">
                                                                <ColorBox colorCode={color.color_code} />
                                                                <span>{color.color_name}</span>
                                                            </Box>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {subset.quantity}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {subset.extra_quantity > 0 && `+${subset.extra_quantity}`}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {stockPrice ? `$${stockPrice.toFixed(2)}` : '-'}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {soldPrice ? `$${soldPrice.toFixed(2)}` : '-'}
                                                    </TableCell>
                                                    <TableCell 
                                                        align="right"
                                                        sx={{ color: getRatioColor(ratio) }}
                                                    >
                                                        {ratio ? ratio.toFixed(2) : '-'}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        // Generate CSV data
                        const headers = [
                            'Part Number',
                            'Name',
                            'Type',
                            'Color ID',
                            'Color Name',
                            'Quantity',
                            'Extra',
                            'Current Stock Min Price',
                            'Current Stock Qty',
                            'Recently Sold Avg Price',
                            'Recently Sold Qty',
                            'Stock/Sold Ratio'
                        ].join(',');

                        const rows = set.subsets.map(subset => {
                            const color = colors.get(subset.color_id);
                            const part = parts[subset.no];
                            const stockPrice = getStockPrice(subset);
                            const soldPrice = getSoldPrice(subset);
                            const ratio = getStockSoldRatio(subset);

                            // Find guides for quantities
                            const stockGuide = part?.type === 'PART'
                                ? part.stockPriceGuides?.find(g => g.color_id === subset.color_id)
                                : part?.stockPriceGuides?.[0];
                            
                            const soldGuide = part?.type === 'PART'
                                ? part.soldPriceGuides?.find(g => g.color_id === subset.color_id)
                                : part?.soldPriceGuides?.[0];

                            return [
                                subset.no,
                                `"${subset.name}"`,
                                part?.type || '',
                                subset.color_id,
                                `"${color?.color_name || ''}"`,
                                subset.quantity,
                                subset.extra_quantity || '',
                                stockPrice ? stockPrice.toFixed(3) : '',
                                stockGuide?.price_data?.total_quantity || '',
                                soldPrice ? soldPrice.toFixed(3) : '',
                                soldGuide?.price_data?.total_quantity || '',
                                ratio ? ratio.toFixed(3) : ''
                            ].join(',');
                        });

                        const csv = [headers, ...rows].join('\n');
                        
                        // Create and download file
                        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = `${set.no}-${set.name.replace(/[/\\?%*:|"<>]/g, '')}-parts.csv`;
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }}
                >
                    Export CSV
                </Button>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>

            <PartDetails
                open={partDetailsOpen}
                onClose={() => {
                    setPartDetailsOpen(false);
                    setSelectedPart(null);
                }}
                part={selectedPart}
            />
        </Dialog>
    );
};
